import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=0792962b&scoped=true"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=0792962b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0792962b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingNewsLetterForm: require('/Users/guysake/dev/Docurba/components/Landing/NewsLetterForm.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VFooter,VImg,VRow})
